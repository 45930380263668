@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');


@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import '@splidejs/splide/css';

// Our own scss below

@import "header";
@import "footer";
@import "blocks";
@import "page";
@import "burger_menu";

.cke_editable{
    background-color: #dfdfdf;
}

// Apply to picture or image wrapper
.img-tint{
    background: #515151;
    img{
        mix-blend-mode: multiply;
    }
}
.img-tint-hover{
    background-color: rgba(81, 81, 81, .38);
    transition: background-color .3s ease-in-out;
    img{
        mix-blend-mode: multiply;
    }
    &:hover{
       background-color: rgb(255, 255, 255); 
    }
    
}

.paper-bg{
    background: url("../assets/paper-bg.png");
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
}
.row-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding:0;
    margin:0;
    width: 100%;
    list-style:none;
    flex-wrap: nowrap;
    gap:1.5rem;
    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        justify-content: center;
    }
}
.row-list-centered{
    @extend .row-list;
    justify-content: center;
}
.two-column-list{
    padding:0;
    margin:0;
    width: 100%;
    list-style:none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:1rem;
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
    }
    li{
        display: flex;
        flex-direction: column;
        img{
            width: 100%!important;
            height: auto!important;
            margin:auto
        }
    }
}
.accordion-button{
    font-size: 1.647rem;
}
.btn{
    white-space: nowrap;
}
.btn-secondary{
    --bs-btn-hover-bg: #25B88F;
    --bs-btn-hover-border-color:#25B88F;
}
.text-green{
    color:var(--bs-green);
}

.opening-hours{
    display: flex;
    flex-direction: column;
}
table td{
    padding-bottom: .5rem;
    padding-top: .5rem;
}
label.float {
    font-weight: 100;
}

.navbar-toggler{
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    transition: border .3s ease-in-out;
    --bs-navbar-toggler-border-color:#fff;
}
.offcanvas-header{
    background-color: var(--bs-primary);
    color: var(--bs-white);
}
.collapse-menu{
    .btn{
        border-radius:  21px 21px 5px 5px;
        margin: 1.5rem 0;
    }
    .has-collapse-button{
        align-items: center;
    }
}
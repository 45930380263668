.burger-menu{
    left:4%;
    bottom:14%;
    z-index: 10;

    .wrapper{
        width: clamp(50px, 10vh, 200px);
        aspect-ratio: 1/1;
        & > * {
            position: absolute;
            top:0;
            left:0;
            right: 0;
            object-fit: contain;
            z-index: -1;
            width: 100%;
        }
        a{
            transition: top 1s ease-in-out, opacity .3s ease-in-out;
            opacity: 0;
            &:hover, &:focus{
                animation: shake 1s infinite;
                animation-timing-function: steps(1);
            } 
        }
        .burger-button{
            content: url("../assets/burger.svg");
            z-index: 10;
            cursor: pointer;
            &:hover, &:focus{
                animation: flip 1s 1; 
                animation-timing-function:ease-in-out;
            }  
            width: 100%;
            height: auto;
        }
        
    }
    &.active{
        .wrapper > a{
            z-index: 10;
            opacity: 1;
        }
        &::after{
            content:"";
            top:0;
            left:0;
            right:0;
            bottom:0;
            background-color: rgba(17, 16, 16, .78);
            position: fixed;
            mix-blend-mode: multiply;
        }
    }

}

@keyframes flip {
    0%   {
        top: 50%;
        transform: translateY(-50%)rotateX(0deg)scale(1);
    }
   
    50%   {
        top: 50%;
        transform: translateY(-50%)rotateX(360deg)scale(1.2);
    }
  
    100%   {
        top: 50%;
        transform: translateY(-50%)rotate(0deg)scale(1);
    }
   
  }
  @keyframes shake {
    0%   {
        transform: rotate(0deg);
    }
    25%   {
        transform: rotate(8deg);
    }
    50%   {
        transform: rotate(0deg);
    }
    75%   {
        transform: rotate(-8deg);
    }
    100%   {
        transform: rotate(0deg);
    }
   
  }
@keyframes shake {
    0%   {
        transform: rotate(0deg)scale(1.2);
    }
    25%   {
        transform: rotate(8deg)scale(1.2);
    }
    50%   {
        transform: rotate(0deg)scale(1.2);
    }
    75%   {
        transform: rotate(-8deg)scale(1.2);
    }
    100%   {
        transform: rotate(0deg)scale(1.2);
    }
   
}

.navbar-brand:hover img{
    animation: shake 1s infinite;
    animation-timing-function: steps(1);
}

.block-banner-row{
   
    a:hover{
       
        animation: shake 1s infinite;
        animation-timing-function: steps(1);
    }   
}
.block-content-bg-image{
    background: linear-gradient(90deg, rgba(0,0,0,0.66) 0%, rgba(255,255,255,0.66) 100%);
    & > div{
        padding:clamp(2rem, 20vw, 8rem) 0; 
    }
    img{
        mix-blend-mode: multiply;
    }
}
.block-floating-testimonials{

    .translate{
        transform: translateY(-40%);
        @include media-breakpoint-down(md) {
            transform: none; 
        }
    }
}
.block-content-image, .block-image-content{
    img{
        max-width: 500px;
    }
}
.block-banner-sliding-door{
    .banners{
        height: 550px;
        background-color: var(--bs-secondary);
        .banner-stage{
            display:flex;
            height: 100%;
            .banner-image {
                height:100%;
                flex-basis: 60px;
                position: relative;
                transition:flex 1s ease;
                background-position:center;
                background-size:cover;
                &:before { /*darken*/
                    content:"";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                 
                    opacity: 1;
                    transition: background-color .3s ease-in-out, opacity .3s ease-in-out;
                }
                &:first-child{
                    &:before{
                        background-color: var(--bs-purple);
                    }
       
                }
                &:nth-child(7n+2):before{
                    background-color: var(--bs-red);
                }
                &:nth-child(7n+3):before{
                    background-color: var(--bs-pink);
                }
                &:nth-child(7n+4):before{
                    background-color: var(--bs-yellow);
                }
                &:nth-child(7n+5):before{
                    background-color: var(--bs-green);
                }
                &:nth-child(7n+6):before{
                    background-color: var(--bs-cyan);
                }
                &:nth-child(7n+7):before{
                    background-color: var(--bs-purple);
                }
                &.active {

                    &:before { /*darken*/
                        content:"";
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background-color: #515151;
                        opacity: 0.54;
                    }
                    flex-grow: 1;
                    @include media-breakpoint-up(md) {
                        .banner-content{
                            padding-left: 2rem;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            // max-width: 500px;
                            gap: 1rem;
                            .banner-name {
                                transform: rotate(0deg);
                                font-size: 2.706rem;
                                white-space: wrap;
                                color:#ffffff!important;
                            }
                            .banner-name, .banner-title, .banner-caption{
                                position: static;
                                display: inline-block;
                            }
                        }
                    }
                    @include media-breakpoint-down(md) {
                        .banner-name {
                            color:#ffffff!important;
                        }
                    }
                }
                .banner-content{
                    .banner-title {
                        position:absolute;
                        top:30%;
                        left:15%;
                        color: #ffffff;
                        font-size:1.529rem;
                        font-weight:bold;
                        transition: opacity 1s ease;
                        display: none;
                        max-width: 500px;
                    }
                    .banner-name{
                        // transition: all .3s ease-in-out;
                        transform: rotate(-90deg);
                        display: block;
                        position: absolute;
                        left: 10px;
                        bottom: 0;
                        transform-origin: top left;
                        font-weight:bold;
                        color:#ffffff;
                        
                        white-space: nowrap;
                        font-size: 20px;
                    }
                    .banner-caption{
                        background-color: var(--bs-secondary);
                        border-radius: 21px;
                        display: none;
                        color: #ffffff;
                        width: fit-content;
                        padding: 6.375px 21.25px;
                    }
                }
               
            }
        }
        .banner-navigation{
            display:none;
        }
    }
}
@import "variables";
.brick-bg{
    background: url('../assets/brick-bg.jpg');
}

footer{
    background-size: cover;
    background-color:#8c8c8c;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    .logo{
        width: 100%; 
        max-width: 400px;
        height: auto;
        // transform: scale(1.2);
        svg{
            color: var(--bs-yellow);
            .logo-bg{
                fill: var(--bs-green)!important; 
            }
        }
    }
    .site-map{
        .depth-0{
            margin-bottom: 2rem;
            &.has-sub{
                ul{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .nav-link{
            text-transform: uppercase;
            padding:10px 10px;
            max-width: 185px;
            font-size: 17px;
            &:hover{
                color: var(--bs-yellow)!important;
            }
        }
    }

    .bykeo a{
        color: #fff;
        font-weight: 100;
        img {
            filter: brightness(0)invert(1);
        }
    } 
}
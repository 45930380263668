@import "variables";

header{
    .navbar-brand{
        height: auto;
        position: relative;
        width: 125px;
        align-self: stretch;
        img{
            position: absolute;
            width: 125px;
            height: 125px!important;
            z-index: 2;
        }
    }
    .nav-link{
        height: 100%;
        display: flex;
        align-items: center;
        &:hover{
            background: $red;
        }
    }
    .btn-group{
        border-radius: 0!important;
    }
    .dropdown-menu .dropdown-menu {
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
       
    }
    .depth-1{
        .nav-link{
            padding-top: .5rem;
            padding-bottom: .5rem;
            text-transform: capitalize!important;
        }
    }
   
    .depth-0 > .nav-item > .btn-group > .dropdown-toggle-split{
        padding-left: .25rem;
        padding-right: .25rem;
    }
    .depth-1 .dropdown-toggle::after{
        margin-left: 1rem;
    }
    .dropdown-menu> li:hover > .btn-group > .dropdown-menu {
        display: block;
    }

    .navbar-nav > .nav-item:last-child > .nav-link{
        background-color: var(--bs-secondary);
        transition: background-color .15s ease-in-out;
        border-radius: 21px;
        color: #ffffff;
        width: fit-content;
        padding: 6.375px 21.25px;
        margin-left: .75rem;
        &:hover{
            background-color: var(--bs-green);
        }
    }

    .nav-link.dropdown-toggle.show{
        background-color: $red;
    }
    .dropdown-toggle-split, .dropdown-toggle{
        display: flex;
        align-items: center;
        &::after{
            color: #fff;
            border:none;
            content: url("../assets/caret.svg");
        }
        &:hover{
            background: $red;
        }
    }
    .btn-group{
        & > .nav-link, & > .dropdown-toggle{
            transition: all .15s ease-in-out!important;
        }
        &:hover{
            & > .nav-link, & > .dropdown-toggle{
                background: $red!important;
            }
        }
    }
    .dropdown-menu{
        transition: opacity .15s ease-in-out!important;
        display: block;
        opacity: 0;
        pointer-events: none;
        top: 100%;
        &.show{
            opacity: 1;
            pointer-events: all;
        }
    }
    @include media-breakpoint-down(xl) {
        .navbar-brand img{
            top:0;
        }
    }
}
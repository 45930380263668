.splash-section{
    min-height: max(300px, 25vw); 
}
.t-home{
    .splash-section{
        height:100vh; 
     }
}

// .t-product{
//     footer{
//         .logo{
//             svg{
//                 color: var(--bs-purple);
//                 .logo-bg{
//                     fill: var(--bs-yellow)!important; 
//                 }
//             }
//         }
//     }
// }
// .t-product-item{
//     footer{
//         .logo{
//             svg{
//                 color: var(--bs-white);
//                 .logo-bg{
//                     fill: var(--bs-purple)!important; 
//                 }
//             }
//         }
//     }
// }
.t-contact{
    .contact-map{
        --bs-aspect-ratio:clamp(300px,30%,600px);
    }
    .office-cards > div{
        & > div{
            position: relative;
            padding:0;
            padding-bottom: 2rem;
            transition: background-color .3s ease-in-out;
        }
        &:hover > div{
            background-color: white!important;
        }
        a{
            color:black;
        }
   
        &:nth-child(3n+1) > div{
            background-color: $pink;
        }
        &:nth-child(3n+2) > div{
            background-color: $cyan;
        }
        &:nth-child(3n+3) > div{
            background-color: $yellow;
        }
    }
    .contact-header, table tr > td:first-child{
        min-width: 150px;
    }
}
.site-links{
    svg{
        height: 36px;
        width: 36px;
    }
}
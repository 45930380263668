// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables 
// @import "../../node_modules/bootstrap/scss/_variables.scss"


// Theme colors
$primary: #F7B727;

$secondary: #5A469C;
$red: #EE2527;
$green:#25B88F;
$purple:#5A469C;
$cyan:#B9E3EB;
$pink: #F491A8;
$yellow: #F7B727;
// For other theme color
// refer to https://getbootstrap.com/docs/5.3/customize/sass/ for more details
$success:       $green ;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red lt;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// Body
// $body-font-weight: 700;
$font-weight-base: 700;
$headings-font-weight: 700;
// Fonts
$primary-text-emphasis: #fff;
$body-emphasis-color: #fff;

$font-family-base: 'Montserrat', Arial, Helvetica, sans-serif;
$font-size-base: 1rem;

// Links
$link-color: #26658d;
$link-decoration: none;

// Navbar
$navbar-light-icon-color: #fff;

// Font
$font-size-root: 17px;
// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

$h1-font-size: 4.235rem;     //72px
$h2-font-size: 2.706rem; //46px
$h3-font-size: 1.647rem; //28px
$h4-font-size: 1.529rem; //26px
$h5-font-size: 1.176rem; //20px


// Navbar
$navbar-padding-y:0;
$nav-link-padding-y:1.5rem;
$nav-link-font-size:17px;
$navbar-light-color:#FFFFFF;
$navbar-nav-link-padding-x: .75rem;

// Dropdown
$dropdown-color: #fff;
$dropdown-bg: $red;
$dropdown-border-radius: 0;
$dropdown-spacer: -1px;
$dropdown-border-width:0;
$dropdown-link-active-bg: $red;
$dropdown-min-width: 100%;

// Button
$btn-border-radius: 21px;
$btn-padding-x:1.25rem;
$btn-font-weight: 700;
$btn-color: #fff;
// Form
$focus-ring-width: 1px;
$input-border-radius:  0px;
$input-bg: transparent;
$input-focus-border-color: $purple;
$input-focus-color:  $purple;

// Accordion
$accordion-button-active-bg: #fff;
$accordion-button-color: $green;

$accordion-button-active-bg: #fff;          
$accordion-button-active-color: $green;
$accordion-icon-active-color: #000;

$pagination-active-color: #fff;
$pagination-active-bg: $purple;  
$pagination-active-border-color: $purple